<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
      <!-- <el-form-item label="文章类型" prop="title">
        <el-col :xl="10">
          <el-select v-model="ruleForm.classify" placeholder="请选择" style="width: 100%" @change="changeClassify">
            <el-option v-for="i in classfiyTypeList" :key="i.id" :label="i.name" :value="i.id">
            </el-option>
          </el-select>
        </el-col>
      </el-form-item> -->
      <el-form-item :label="title_info + '标题'" prop="title">
        <el-col :xl="10">
          <el-input placeholder="请输入标题" v-model="ruleForm.title" clearable />
        </el-col>
      </el-form-item>
      <el-form-item :label="title_info + '副标题'">
        <el-col :xl="10">
          <el-input placeholder="请输入副标题" v-model="ruleForm.subTitle" clearable />
        </el-col>
      </el-form-item>
      <el-form-item :label="title_info + '来源'" prop="originAuthor">
        <el-col :xl="10">
          <el-input placeholder="来源" v-model="ruleForm.originAuthor" clearable />
        </el-col>
      </el-form-item>
      <el-form-item :label="title_info + '标签'" prop="title">
        <el-col :xl="10">
          <el-select v-model="ruleForm.labelId" multiple placeholder="请选择" style="width: 100%">
            <el-option v-for="i in tagsList" :key="i.labelId" :label="i.labelName" :value="i.labelId">
            </el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item :label="title_info + '封面'" prop="photos">
        <el-upload :action="$store.state.uploadingUrl" list-type="picture-card" class="avatar-coverImg"
          :class="{ disabled: uploadDisabled }" :limit="1" :on-progress="handProgress" :on-success="handleSuccess"
          :on-remove="handleRemove" :file-list="fileList">
          <i class="el-icon-plus"></i>
        </el-upload>
        <div class="h100p d-flex align-items-center">
          <p>建议上传750x600，格式可为png、jpg或jpeg</p>
        </div>
      </el-form-item>
      <el-form-item :label="title_info + '分享图'">
        <el-upload :action="$store.state.uploadingUrl" list-type="picture-card" class="avatar-coverImg"
          :class="{ disabled: uploadDisabled2 }" :limit="1" :on-progress="handProgressShare"
          :on-success="handleSuccessShare" :on-remove="handleRemoveShare" :file-list="fileListShare">
          <i class="el-icon-plus"></i>
        </el-upload>
        <div class="h100p d-flex align-items-center">
          <p>建议上传750x600，格式可为png、jpg或jpeg</p>
        </div>
      </el-form-item>
      <el-form-item label="Banner图" prop="photos" v-if="classify == 5">
        <el-upload :action="$store.state.uploadingUrl" list-type="picture-card" class="avatar-coverImg"
          :class="{ disabled: uploadDisabled1 }" :limit="1" :on-progress="handProgressLength"
          :on-success="handleSuccessLength" :on-remove="handleRemoveLength" :file-list="fileListLength">
          <i class="el-icon-plus"></i>
        </el-upload>
        <div class="h100p d-flex align-items-center">
          <p>建议上传700x220，格式可为png、jpg或jpeg</p>
        </div>
      </el-form-item>
      <el-form-item :label="title_info + '内容'">
        <quill-editor style="height: 100%" v-model="ruleForm.details" ref="myQuillEditor" :options="editorOption" />
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker v-model="ruleForm.createTime" type="datetime" placeholder="选择日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="是否发布" prop="isDrafts">
        <el-switch v-model="ruleForm.isDrafts" active-text="发布" inactive-text="关闭" />
        <span class="ml10" style="vertical-align: middle; color: rgb(253, 99, 78)">关闭状态即保存到"草稿箱"</span>
      </el-form-item>
      <el-form-item label="是否置顶" prop="isTop">
        <el-switch v-model="ruleForm.isTop" active-text="是" inactive-text="否" />
      </el-form-item>
      <el-form-item label="开启三方平台" prop="">
        <el-switch v-model="isExplore" active-text="是" inactive-text="否" @change="isExploreChange" />
      </el-form-item>

      <div v-if="isExplore">
        <el-form-item label="平台类型">
          <el-select v-model="ruleForm.tookType" placeholder="请选择" @change="changeExploreType" style="width: 100%">
            <el-option :disabled="item.id == 0 ? false : true" v-for="item in typeList" :key="item.id" :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择分类">
          <el-select v-model="ruleForm.selectClass" placeholder="请选择" @change="changeClassfiy" style="width: 100%">
            <el-option v-for="item in classfiyList" :key="item.typeId" :label="item.labelName" :value="item.typeId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择内容">
          <el-select v-model="ruleForm.exploreId" placeholder="请选择" @change="changeApply" style="width: 100%">
            <el-option v-for="item in applyList" :key="item.id" :label="item.title" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="按钮名称" prop="originAuthor">
          <el-col :xl="10">
            <div class="flex a">
              <el-input placeholder="按钮名称" v-model="ruleForm.buttonName" clearable />
              <a class="ml-30 text-center ra-2" v-if="ruleForm.exploreId" target="_blank" :href="ruleForm.exploreUrl"
                style="color:#FFFFFF;width: 100px;background-color: #35455b;">预览</a>
            </div>

          </el-col>
        </el-form-item>
        <el-form-item label="是否直接打开" prop="originAuthor">
          <el-col :xl="10">
            <el-switch v-model="isOpen" active-text="是" inactive-text="否" />
          </el-col>
        </el-form-item>
        <el-form-item label="允许复制地址" prop="originAuthor">
          <el-col :xl="10">
            <el-switch v-model="allowCopy" active-text="是" inactive-text="否" />
          </el-col>
        </el-form-item>
      </div>
      <el-form-item label="关联短视频" prop="">
        <el-switch v-model="linkShortVideo" active-text="是" inactive-text="否" @change="linkShortVideoChange" />
      </el-form-item>
      <div v-if="linkShortVideo">
        <el-form-item label="选择短视频">
          <el-select v-model="ruleForm.shortVideoId" placeholder="请选择" style="width: 100%">
            <el-option v-for="i in videoList" :key="i.videoId" :label="i.title" :value="i.videoId">
            </el-option>
          </el-select>
        </el-form-item>
      </div>

    </el-form>
  </div>
</template> 

<script>
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";

import { Video } from '@/utils/video';
// const quill = require('quill');
Quill.register({ 'formats/video': Video }, true);

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = "请填写文章内容";
//探索列表
import { exploreQueryPage } from "@/api/explore";
import { labelQueryPage } from "@/api/articleManagement";
import { blendQueryPage, typeQueryPage } from "@/api/drawingBed";
import {
  queryPage
} from "@/api/videoManage";
export default {
  props: ['classify', 'title_info'],
  data() {
    var valiIcon = (rule, value, callback) => {
      // 图片验证
      if (!this.ruleForm.photos) {
        callback();
      } else {
        callback();
      }
    };
    return {
      isOpen: false,
      linkShortVideo: false,
      allowCopy: false,
      editorOption: quillConfig,
      disabled: false,
      ruleForm: {
        title: "",
        originAuthor: "",
        dynamicId: "",
        details: "",
        isDrafts: false,
        photos: "",
        isExplore: false,
        explore: "",
        createTime: new Date(),
      },
      fileList: [],
      fileListLength: [],
      rules: {
        title: [{ required: true, message: "请输入文章标题", trigger: "blur" }],
        photos: [{ required: true }],
        isDrafts: [{ required: true, message: "请输入文章内容" }],
        // details: [{ required: true, message: "请输入文章内容" }],
      },
      tagsList: [],
      defaultTime: "",
      isExplore: false,
      typeList: [
        {
          title: "图壳应用",
          id: 0,
        },
        {
          title: "图壳模型",
          id: 1,
        },
        {
          title: "近迁全景",
          id: 2,
        },
      ],
      classfiyList: [],
      applyList: [],
      classfiyTypeList: [
        {
          id: 1,
          name: "获客文章",
        },
        {
          id: 2,
          name: "产品介绍",
        },
        {
          id: 3,
          name: "解决方案",
        },
      ],
      fileListShare: [],
      videoList: [],
    };
  },
  created() {
    this.getTagsList();
  },
  components: {
    quillEditor,
  },
  computed: {
    uploadDisabled() {
      return this.ruleForm.photos !== "";
    },
    uploadDisabled1() {
      return this.fileListLength.length > 0;
    },
    uploadDisabled2() {
      return this.fileListShare.length > 0;
    },
  },
  methods: {
    getVideo() {
      if (this.videoList.length > 0) {
        return
      }
      let data = {
        pageSize: 100,
        pageNum: 1,
        queryType: '',
      };
      queryPage(data).then(res => {
        console.log(res, '视频列表');
        if (res.data.pageInfo.list) {
          this.videoList = res.data.pageInfo.list
        }
      })
    },
    linkShortVideoChange(e) {
      if (e) {
        this.getVideo();
      }
    },
    changeExploreType(e) {
      if (e == 0) {
        this.getClassfiyList();
      }
    },
    // 获取分类列表
    getClassfiyList() {
      let data = {
        pageNum: 1,
        pageSize: 1000,
      };
      typeQueryPage(data).then((res) => {
        this.classfiyList = res.data.records;
      });
    },
    changeClassfiy(e) {
      // this.ruleForm.exploreId='',
      // this.ruleForm.exploreUrl='',
      this.getapplyList(e);
    },
    getapplyList(id) {
      let data = {
        pageNum: 1,
        pageSize: 100,
        typeId: id,
      };
      blendQueryPage(data).then((res) => {
        this.applyList = res.data.records;
      });
    },
    changeClassify(e) {
      this.ruleForm.labelId = [];
      this.getTagsList(this.classify);
    },
    // 获取分类列表
    getTagsList() {
      let data = {
        pageNum: 1,
        pageSize: 1000,
        classify: this.classify,
      };
      labelQueryPage(data).then((res) => {
        this.tagsList = res.data.records;
        this.tagsList.forEach((el) => {
          el.labelId = String(el.labelId);
        });
      });
    },
    isExploreChange(e) {
      if (e == false) {
        this.ruleForm.tookType = '';
        this.ruleForm.selectClass = '';
        this.ruleForm.exploreId = '';
        this.ruleForm.exploreUrl = '';
        this.ruleForm.buttonName = '';
      }
    },
    handleRemove(file, fileList) {
      this.ruleForm.photos = "";
    },
    handProgress(event, file, fileList) {
      this.$message({
        message: "正在上传",
      });
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.photos = `https://cdn-oss.jquen.com/${response.data}`;
    },
    // 分享图
    handleRemoveShare(file, fileList) {
      this.fileListShare = [];
      this.ruleForm.shardImage = "";
    },
    handProgressShare(event, file, fileList) {
      this.$message({
        message: "正在上传",
      });
    },
    handleSuccessShare(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.shardImage = `https://cdn-oss.jquen.com/${response.data}`;
    },
    handleRemoveLength(file, fileList) {
      this.fileListLength = [];
      this.ruleForm.lengthPhotos = "";
    },
    handProgressLength(event, file, fileList) {
      this.$message({
        message: "正在上传",
      });
    },
    handleSuccessLength(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.lengthPhotos = `https://cdn-oss.jquen.com/${response.data}`;
      this.fileListLength = fileList;
    },
    submitForm(payload) {
      // if (!this.ruleForm.classify) {
      //   return this.$message.error("请选择文章类型");
      // }
      if (!this.ruleForm.photos) {
        return this.$message.error("请上传文章封面");
      }
      if (this.ruleForm.classify == 5) {
        if (!this.ruleForm.lengthPhotos) {
          return this.$message.error("请上传文章Banner图");
        }
      }
      if (!this.ruleForm.shardImage) {
        this.ruleForm.shardImage = this.ruleForm.photos;
      }

      // if (!this.ruleForm.details) {
      //   return this.$message.error("请填写文章内容");
      // }
      if (this.isExplore && this.ruleForm.exploreId == "") {
        return this.$message.error("请选择要绑定的探索内容");
      }
      if (this.linkShortVideo && this.ruleForm.shortVideoId == '') {
        return this.$message.error("请选择要绑定的短视频内容");
      }
      this.ruleForm.isExplore = this.isExplore ? "1" : "0";
      if (this.isExplore) {
        this.ruleForm.isOpen = this.isOpen ? "1" : "0";
        this.ruleForm.allowCopy = this.allowCopy ? 1 : 0;
      }
      this.ruleForm.linkShortVideo = this.linkShortVideo ? 1 : 0;
      this.ruleForm.labelId = this.ruleForm.labelId.join();
      if (!this.ruleForm.labelId) {
        return this.$message.error("请选择分类");
      }
      this.ruleForm.classify = this.classify;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.ruleForm));
          data.isDrafts = data.isDrafts ? 0 : 1;
          data.isTop = data.isTop ? 1 : 0;
          // if(payload) data.isDrafts = 0 ;
          console.log(data, "提交的数据");
          this.$emit("submitForm", data);
        } else {
          return false;
        }
      });
    },
    baocuo() {
      this.ruleForm.labelId = this.ruleForm.labelId.split(",");
    },
    changeApply(e) {
      console.log(e, "选择");
      this.applyList.forEach((el) => {
        if (el.id == e) {
          this.ruleForm.exploreUrl = el.realUrl;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
  display: none;
}

::v-deep .avatar-coverImg .el-upload--picture-card {
  width: 200px;
  height: 160px;
}

::v-deep .avatar-coverImg .el-upload-list__item {
  width: 200px;
  height: 160px;
}
</style>